<template>
  <DialogModal ref="modal" class="product-modal" btnLabel="Stampa QR code" title="Stampa QR code" @close="$emit('close', $event)">
    <template #actions>
      <div class="w-100 text-end">
        <button class="btn btn-primary" @click="submitForm">Salva</button>
      </div>
    </template>

    <template #default>
      <FormContainer @submitForm="submitForm">
        <div class="row">
          <FormInputTextWidget
            class="col-12 col-lg-4"
            label="Riga primo QR code"
            type="number"
            :min="1"
            :max="10"
            v-model="row"
          />

          <FormInputTextWidget
            class="col-12 col-lg-4"
            label="Colonna primo QR code"
            type="number"
            :min="1"
            :max="7"
            v-model="column"
          />

          <FormInputTextWidget
            class="col-12 col-lg-4"
            label="Qr code da generare"
            type="number"
            :min="1"
            v-model="quantity"
          />
        </div>
      </FormContainer>
    </template>
  </DialogModal>
</template>

<script>

import DialogModal from '@/libs/Modals/components/DialogModal.vue';
import FormContainer from '@/views/components/Form/FormContainer.vue';
import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';

export default {
  components: { DialogModal, FormContainer, FormInputTextWidget },
  data () {
    return {
      row: 1,
      column: 1,
      quantity: 70,
    };
  },
  methods: {
    submitForm () {
      this.$emit('submit', this.row, this.column, this.quantity);
      this.$emit('close');
    },

    closeModal () {
      this.$emit('close');
    },
  },
};

</script>

<style lang="scss">

.product-modal {
  .modal-dialog {
    max-width: 800px;
  }
}

</style>
