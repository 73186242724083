<template>
  <main v-if="wine">
    <PageTitleContainer fluid><PageTitle>{{ wine.wineName }}</PageTitle></PageTitleContainer>

    <div>
      <p><strong>Nome del vino</strong>: <router-link :to="{ name: 'inventory.wines.show', params: { id: wine.wine } }">{{ wine.wineName }}</router-link></p>
      <p><strong>Produttore</strong>: {{ wine.producerName }}</p>
      <p><strong>Tipologia</strong>: {{ wine.wineTypeName }}<span v-if="wine.wineSparkling"> [Spumante]</span></p>
      <p><strong>Confezione</strong>: {{ wine.packageName }} {{ wine.packageMaterial }} {{ wine.packageVolume / 1000 }}L</p>
      <p><strong>Annata</strong>: {{ wine.vintageName }}</p>
      <p><strong>Peso</strong>: {{ wine.weight }}</p>
      <p><strong>Gradazione</strong>: {{ wine.alcoholContent }}</p>
      <p><strong>Quantità</strong>: {{ wine.quantity }}</p>
      <p><strong>Prezzo di acquisto</strong>: {{ wine.purchasePrice / 100 }} €</p>
      <p><strong>Prezzo di vendita di default</strong>: {{ wine.defaultSellingPrice / 100 }} €</p>
    </div>

    <div>
      <button type="button" class="btn btn-primary me-3" @click.prevent="$openModal('qr')">Stampa QR-code</button>
      <button type="button" class="btn btn-primary me-3" @click.prevent="printSingleQrCode">Stampa QR-code singolo</button>
      <button type="button" class="btn btn-outline-primary" @click.prevent="$openModal('edit')">Modifica</button>
    </div>

    <ProductModal v-if="$modals.edit" :edit="true" :value="wine" @close="$closeModal('edit')" @submitForm="saveProduct" />
    <PrintQrCodeModal v-if="$modals.qr" @close="$closeModal('qr')" @submit="downloadLabels" />
  </main>
  <span v-else></span>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import ModalsManagerMixin from '@/libs/Modals/mixins/manager.js';

import { saveBlobAsFile } from '@/libs/Downloader/helpers/saveStringAsFile.js';

import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';
import ProductModal from '@/views/components/Modals/ProductModal.vue';
import PrintQrCodeModal from '@/views/components/Modals/PrintQrCodeModal.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  if (!to?.params?.id) {
    return next(from);
  }

  $api.fetchWinePackage(to.params.id)
    .then(res => next(vm => {
      vm.wine = res?.data;
    }))
    .catch(() => next(from))
  ;
}

export default {
  components: { PageTitleContainer, PageTitle, ProductModal, PrintQrCodeModal },
  mixins: [ ModalsManagerMixin ],
  data () {
    return {
      loading: false,
      wine: null,
    };
  },
  methods: {
    saveProduct (product) {
      this.$api.updateWinePackage(this.wine.id, product)
        .then((res) => {
          this.wine = res.data;
          this.$closeModal('edit');
        })
      ;
    },
    downloadLabels (row, column, quantity) {
      let rowToSend = row - 1;
      if (rowToSend < 0) {
        rowToSend = 0;
      } else if (rowToSend > 9) {
        rowToSend = 9;
      }

      let columnToSend = column - 1;
      if (columnToSend < 0) {
        columnToSend = 0;
      } else if (columnToSend > 6) {
        columnToSend = 6;
      }

      this.$api.fetchWinePackageLabels(this.wine.id, rowToSend, columnToSend, quantity)
        .then(res => {
          return saveBlobAsFile(`${this.wine.id}.pdf`, res.data);
        })
      ;
    },
    printSingleQrCode () {
      this.$api.fetchWinePackageSingleLabel(this.wine.id)
        .then(res => {
          return saveBlobAsFile(`${this.wine.id}-single.pdf`, res.data);
        })
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
